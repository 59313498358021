import {ICanvasOverrideStyles} from './interfaces';
import {DEFAULT_STYLES} from './constant';
import {defaultsDeep, get, mergeWith} from 'lodash-es';
import {EventStructure, ITboEvent, UnifiedEvent} from '../../models';

export const mergeStyles = (styles: ICanvasOverrideStyles): typeof DEFAULT_STYLES => {
  return mergeWith(DEFAULT_STYLES, styles);
};

export const buildPathLabel = (name?: string, type?: string) => {
  const start = name ? `${name}\n` : '';
  const end = type ? type : '';
  return `${start}${end}`;
};

/**
 * Inserts variables into template string like 'foo bar {{obj.prop.a}}'
 */
export const parseTemplateString = (template: string, object: EventStructure | UnifiedEvent | ITboEvent) => {
  if (!template || !object) return '';
  return template.replace(/{{.+?}}/g, (match) => {
    const path = match.substr(2, match.length - 4).trim();
    const value = get(object, path);
    return value
  });
};

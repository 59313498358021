import {get, isNumber} from 'lodash-es';
import {CanvasLayer, EventStructure, UnifiedEvent} from '../../models';
import {Bbox, ITboEvent} from '../../models/TboEvent/ITboEvent';
import {parseTemplateString} from './helpres';
import {StateArguments} from './interfaces';

type PolygonPoints = Bbox | {bbox: Bbox; objectType: string}[] | undefined | [number, number];
const isBbox = (p: any): p is Bbox => p?.length === 4 && p?.every(isNumber);
const isHalfBbox = (p: any): p is [number, number] => p?.length === 2 && p?.every(isNumber);

class CanvasStateService {
  createLineData(data: CanvasLayer, eventData: EventStructure | UnifiedEvent | ITboEvent, naturalSize: {x: number; y: number}) {
    const {
      title: {template, position},
      path,
      styles,
      pointsType
    } = data;

    const lineCoords = get(eventData, path) as [number, number][];

    if (Array.isArray(lineCoords)) {
      const tmpArr = [];
      for (let index = 0; index < lineCoords.length; index++) {
        const start = lineCoords[index];
        let stop = lineCoords[index + 1];

        if (!stop) {
          stop = lineCoords[0];
        }

        tmpArr.push({
          id: Date.now().toString(),
          name: parseTemplateString(template, eventData),
          style: styles,
          start: {index: 0, x: start[0], y: start[1]},
          end: {index: 1, x: stop[0], y: stop[1]},
          directed: true
        });
      }
      return tmpArr;
    }
    return [];
  }

  createPolygonsData(data: CanvasLayer, eventData: EventStructure | UnifiedEvent | ITboEvent, naturalSize: {x: number; y: number}) {
    const {
      title: {template, position},
      path,
      styles,
      pointsType
    } = data;

    const polygonCoords = get(eventData, path) as PolygonPoints;
    if (!polygonCoords) return {};

    if (isBbox(polygonCoords) || isHalfBbox(polygonCoords)) {
      return {
        id: Date.now().toString(),
        name: parseTemplateString(template, eventData),
        namePosition: position || 'bottom',
        style: styles,
        points: this.createPointsFromBBox({bbox: polygonCoords, naturalSize, pointsType})
      };
    }

    return polygonCoords.map((data) => {
      return {
        id: Date.now().toString(),
        name: parseTemplateString(template, eventData),
        namePosition: position || 'bottom',
        style: styles,
        points: this.createPointsFromBBox({
          bbox: data.bbox,
          naturalSize,
          pointsType
        })
      };
    });
  }

  getInitialState(params: StateArguments) {
    const {eventData, eventTypeData, naturalSize, styles} = params;
    const polygons: any[] = [];
    const lines: any[] = [];

    // ExtraLayers
    if ('canvasLayers' in eventTypeData && eventTypeData.canvasLayers) {
      Object.values(eventTypeData.canvasLayers).forEach((canvasLayerData) => {
        canvasLayerData.type === 'line'
          ? lines.push(this.createLineData(canvasLayerData, eventData, naturalSize))
          : polygons.push(this.createPolygonsData(canvasLayerData, eventData, naturalSize));
      });
    }

    // BaseLayer
    if (eventData?.bbox) {
      polygons.push(
        this.createPolygonsData(
          {path: 'bbox', pointsType: 'relative', styles: styles, title: {template: ''}, type: 'polygon'},
          eventData,
          naturalSize
        )
      );
    }

    return {
      itemStore: {
        polygonStore: {
          items: polygons.flat(),
          editableLimit: 0
        },
        lineStore: {
          items: lines.flat(),
          editableLimit: 0
        }
      }
    };
  }

  createPointsFromBBox(data: {
    bbox: Bbox | [] | [number, number];
    naturalSize: {x: number; y: number};
    pointsType: string;
  }) {
    const {bbox, naturalSize, pointsType} = data;
    if (!bbox?.length) return {};

    const [x, y, width, height] = bbox;

    let points;

    if (width && height) {
      points = [
        {x, y},
        {x: x + width, y},
        {x: x + width, y: y + height},
        {x: x, y: y + height}
      ];
    } else {
      points = [{x, y}];
    }

    if (pointsType !== 'absolute') {
      points = points?.map((val) => {
        val.x = val.x * naturalSize.x;
        val.y = val.y * naturalSize.y;
        return val;
      });
    }

    return points.map((val, index) => Object.assign(val, {index}));
  }
}

export default new CanvasStateService();

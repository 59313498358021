/** @jsx jsx */
import {jsx} from '@emotion/react';
import {Dispatch, FC, SetStateAction} from 'react';
import {Actions} from '../';
import {useLang} from '../../../providers/LangProvider';
import {useEventTypes} from '../../../providers/EventTypesProvider';
import {useGetFields} from '../../../hooks';
import {$actionsPosition, $alignBottom, $entry, $fields, $heading} from './styles';
import {isAssignmentEvent, isAssignmentEventType, UnifiedEvent} from '../../../models';

type HeaderProps = {
  event: UnifiedEvent
  showActions: boolean;
  onCleanQueue?: () => void
  setActualEvent: Dispatch<SetStateAction<UnifiedEvent>>
};
type FieldDescriptor = {
  field: string;
  header: string;
  body: (event: UnifiedEvent) => JSX.Element | string | number | null | undefined;
}[];

export const Header: FC<HeaderProps> = ({event, showActions, onCleanQueue, setActualEvent}) => {
  const columns = useGetFields();
  const lang = useLang();
  const {eventTypes} = useEventTypes();
  const isAssignment = isAssignmentEvent(event);

  const mapDescriptor = ({body, field, header}: FieldDescriptor[0]) => {
    const value = body(event) as JSX.Element;
    const hasValue = Object.values(value?.props || {})?.filter(Boolean)?.length;
    return hasValue ? (
      <div key={field} css={$entry}>
        <div>{header}: </div>
        <div>{value}</div>
      </div>
    ) : null;
  };

  let fields = columns
    .filter(({field}) => !['eventType', 'tboEventType'].includes(field))
    .map(mapDescriptor)
    .filter(Boolean);

  if (showActions) fields = fields.reverse();
  // @ts-ignore
  const eventType = eventTypes.get(isAssignment ? (event.eventType || event.assignmentEventTypeName) : event.tboEventType);
  const title = eventType?.[`locale${lang}` as 'localeRu']?.title || eventType?.title || eventType?.name;
  const resolutionRequired = (
    eventType && isAssignmentEventType(eventType)
      ? eventType.resolutionRequired
      : Boolean(eventType)
  );
  return (
    <div>
      <h2 css={$heading}>{title}</h2>
      <div css={[$fields, showActions && $alignBottom]}>{fields}</div>
      {showActions &&
        <Actions
          resolutionRequired={resolutionRequired}
          event={event}
          css={$actionsPosition}
          onCleanQueue={onCleanQueue}
          setActualEvent={setActualEvent}
        />
      }
    </div>
  );
};

import React, {createContext, FC, useContext, useEffect, useState} from 'react';
import {getSharedNotificationSocket, IEntity} from '@netvision/lib-api-gateway';

const NotificationSocketContext = createContext(getSharedNotificationSocket());

export const NotificationSocketProvider: FC = ({children}) => {
  const [socket] = useState(() => getSharedNotificationSocket());
  return <NotificationSocketContext.Provider value={socket}>{children}</NotificationSocketContext.Provider>;
};

export function useNotificationSocket<T extends IEntity>(type: T['type'], onEvent: (entity: T) => void) {
  const socket = useContext(NotificationSocketContext);
  useEffect(() => {
    if (socket) {
      return socket.addListener(type, onEvent);
    }
    return () => undefined;
  }, [socket, type, onEvent]);
}

import {useEffect, useMemo, useState} from 'react';
import {EventsTypes, EventMetaData} from '../../models';
import {MetaDataType} from '../../Root';
import {useApiRepository} from './useApiRepository';

export const useGetMetaData = (type: string, lang: string): MetaDataType | null => {
  const [metaData, setMetaData] = useState<EventMetaData>(null!);
  const { api } = useApiRepository();

  const metadataSetter = (meta: MetaStructure): EventMetaData => {
    if (meta.viewEventJournalDialog || meta.viewEventJournal) {
      return {
        $entityType: type,
        $locale: meta.localeRu.attrs,
        viewTable: meta.viewEventJournalDialog || meta.viewEventJournal
      }
    }

    if (meta.journal) {
      return {
        $entityType: type,
        $locale: meta.locales[lang],
        viewTable: meta.journal
      }
    }

    return {
      $entityType: type,
      $locale: {},
      viewTable: {}
    }
  }
  
  useEffect(() => {
    let aborted = false;

    api.getEntitiesList<MetaStructure>({
      limiter: {
        type: "EntityTypeMetadata"
      },
      filter: {
        q: [
          {
            key: 'entityType',
            value: type,
            operator: '==',
          }
        ],
        attrs: 'journal,viewEventJournalDialog,viewEventJournal,locales,localeRu'
      }
    })
      .then(({ results }) => {
        if (aborted || !results) return;
        setMetaData(metadataSetter(results[0]));
      })

    return () => {
      aborted = true;
    };
  }, [lang, type]);

  return useMemo(() => normalizedMetaData(metaData), [metaData]);
};

const normalizedMetaData = (metaData: EventMetaData): ReturnType<typeof useGetMetaData> => {
  if (!metaData) return null;
  return {
    eventType: metaData.$entityType as EventsTypes,
    locale: metaData.$locale,
    tableSetting: metaData.viewTable
  };
};

type MetaStructure = Record<string, any>

import React from 'react';

const timestampToString = (t: number | string) => new Date(t).toLocaleString('ru-RU');

type Props = {
  timeStamp: number | string;
};

export const DateTimeField = (props: Props) => {
  const {timeStamp} = props;
  return <span>{timeStamp ? timestampToString(timeStamp) : ''}</span>;
};

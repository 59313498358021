/** @jsx jsx */
import {css, jsx} from '@emotion/react';
import React, {FC, useCallback, useEffect, useState} from 'react';
import {IEventObservePayload, IWidgetProps} from '../../IWidgetProps';
import {Dialog} from 'primereact/dialog';
import {ProgressSpinner} from 'primereact/progressspinner';
import {ExternalFileAdapter, ArchivePlayerAdapter} from '../../widget-adapters';
import {useMeasure, useLocale} from '../../hooks';
import {UnifiedEvent, isAssignmentEvent, isTboEvent} from '../../models';
import {ToastProvider, NeedUpdateContext} from '../../providers';
import {useEventTypes} from '../../providers/EventTypesProvider';
import {isArchiveAvailable} from './helpers';
import {Header} from '../header';
import {DialogInformer} from './components';
import {DialogConfirmation} from './components/dialog-confirmation';
import {$dialog} from './styles';
import {useApiRepository} from '../../hooks/api/useApiRepository';
import {useObserver} from '../../hooks/useObserver';
import {useWidgetProps} from '../../hooks/useWidgetProps';
import {PublishPayload} from '@netvision/lib-types-frontend';
import {eventUnifier} from '../../eventUtils';

export const DetailsDialog: FC<{
  event: UnifiedEvent;
  containerId?: string;
  showActions: boolean;
  onCleanQueue?: () => void;
}> = ({event, containerId, showActions, onCleanQueue}) => {
  const {api} = useApiRepository();
  const [measureRef, bounds] = useMeasure<HTMLElement>();
  const {eventBus, eventBusID} = useObserver();
  const {confirmation} = useEventTypes();
  const {widgetProps, setWidgetProps} = useWidgetProps();
  const [actualEvent, setActualEvent] = useState<UnifiedEvent>(event);
  const [needUpdate, setNeedsUpdate] = useState(false);
  const [archiveStatus, setForceArchive] = useState<'close' | 'open' | 'not' | 'error'>('close');
  const [loading, setLoading] = useState(false);

  const onOpenArchive = useCallback(() => {
    let eventEntityId = actualEvent.cameraId || actualEvent.entityId ||'';
    let eventTimestamp: number = actualEvent.timestamp || 0;

    if (isAssignmentEvent(actualEvent)) {
      const {timestamp, createdAt, assignment} = actualEvent;
      if (!assignment) return;
      const {entityId} = assignment;
      eventEntityId = entityId;
      eventTimestamp = timestamp || new Date(String(createdAt)).getTime();
    }

    if (isTboEvent(actualEvent)) {
      const {cameraId, createdAt, timestamp} = actualEvent;
      if (!cameraId) return;
      eventTimestamp = timestamp || new Date(String(createdAt)).getTime();
      eventEntityId = cameraId;
    }

    setLoading(true);
    isArchiveAvailable(api, {
      id: eventEntityId,
      timestamp: eventTimestamp
    })
      .then((res) => {
        setForceArchive(res ? 'open' : 'not')
      })
      .catch((e) => {
        e.message === 'Nothing was found'
          ? setForceArchive('not')
          : setForceArchive('error');
      })
      .finally(() => setLoading(false));
  }, [api, actualEvent]);

  const [parent] = useState(() => {
    const el = document.createElement('div');
    el.id = containerId || '';
    return el;
  });

  const updateEventState = <T,>(payload: PublishPayload<T>) => {
    const {entity, canPrev, canNext, onPrev, onNext, onHide} = payload.data as IEventObservePayload
    setActualEvent(eventUnifier(entity))
    setWidgetProps({
      ...widgetProps,
      props: {
        ...(widgetProps.props || {}),
        canPrev,
        canNext,
        onPrev,
        onNext,
        onHide
      } as IWidgetProps['props']
    })
  };

  useEffect(() => {
    parent.id = containerId || '';
  }, [parent, containerId]);

  useEffect(() => {
    document.body.appendChild(parent);
    let content: HTMLElement;

    const t = setTimeout(() => {
      content = parent.querySelector('div.p-dialog-content') as HTMLElement;
      measureRef(content);
    });

    return () => {
      if (t) {
        clearTimeout(t);
      }
      if (content) {
        measureRef(null);
      }
      parent.remove();
    };
    // eslint-disable-next-line
  }, [parent]);

  useEffect(() => {
    if (eventBusID) {
      eventBus?.subscribe(eventBusID, 'entity:flipped', updateEventState);
    }

    return () => {
      eventBusID && eventBus?.unsubscribe(eventBusID, 'entity:flipped', updateEventState);
    };
  }, [eventBus, eventBusID]);

  const canWatchPreview = archiveStatus === 'close' && !loading;
  const isArchiveError = archiveStatus === 'error' && !loading;
  const isArchiveOpen = archiveStatus === 'open' && !loading;
  const isNotArchiveOpen = archiveStatus === 'not' && !loading;
  const canShowArchive = isArchiveOpen;

  return (
    <NeedUpdateContext.Provider value={{needUpdate, toggleNeedUpdate: () => setNeedsUpdate(true)}}>
      <ToastProvider position={'top-left'}>
        <Dialog
          id="event-details-dialog"
          appendTo={parent}
          visible={!!actualEvent}
          css={$dialog}
          onHide={() => widgetProps.props?.onHide(needUpdate)}
          header={
            <Header
              event={actualEvent}
              showActions={showActions}
              onCleanQueue={onCleanQueue}
              setActualEvent={setActualEvent}
            />
          }
        >
          {canWatchPreview && (
            <ExternalFileAdapter
              event={actualEvent}
              height={bounds?.height || 'auto'}
              onOpenArchive={onOpenArchive}
            />
          )}
          {canShowArchive && (
            <ArchivePlayerAdapter
              event={actualEvent}
              height={bounds?.height || '50vh'}
              skipPreview={Boolean(archiveStatus)}
            />
          )}
          <DialogStatusInformer
            height={bounds?.height || 'auto'}
            isArchiveError={isArchiveError}
            isNotArchiveOpen={isNotArchiveOpen}
            loading={loading}
          />
        </Dialog>
      </ToastProvider>
      {confirmation && <DialogConfirmation {...confirmation} />}
    </NeedUpdateContext.Provider>
  );
};

const DialogStatusInformer = (props: {
  height: number | string;
  isNotArchiveOpen: boolean;
  isArchiveError: boolean;
  loading: boolean;
}) => {
  const {height, isArchiveError, isNotArchiveOpen, loading} = props;
  const locale = useLocale();

  return (
    <React.Fragment>
      {isNotArchiveOpen && <DialogInformer text={locale.archiveStatus.notFound} height={height} />}
      {isArchiveError && <DialogInformer text={locale.archiveStatus.serverError} height={height} />}
      {loading && (
        <DialogInformer height={height}>
          <ProgressSpinner />
        </DialogInformer>
      )}
    </React.Fragment>
  );
};

/** @jsx jsx */
import {css, jsx} from '@emotion/react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { DialogConfirmationProps } from '../../../../IWidgetProps';
import { Fragment } from 'react';

const modalStyles = css`
  .p-dialog-content {
    padding: 0 var(--form-padding);
  }
`

export const DialogConfirmation = ({ header, message, cancelLabel, confirmLabel, onClose, onConfirm }: DialogConfirmationProps) => {
  return (
    <Dialog
      css={modalStyles}
      header={header}
      onHide={onClose}
      visible
      footer={<Fragment>
        <Button className="p-mr-1" onClick={onConfirm} label={confirmLabel} />
        <Button className="p-button-secondary" onClick={onClose} label={cancelLabel} />
      </Fragment>}
    >
      <div className="p-d-flex">{message}</div>
    </Dialog>
  )
}
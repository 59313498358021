import React, {useEffect, useMemo, useState} from 'react';
/** @jsx jsx */
import {css, jsx} from '@emotion/react';
import {Button} from 'primereact/button';
import {useWidgetProps} from '../../../../hooks/useWidgetProps';
import {UnifiedEvent} from '../../../../models';

const $prevNext = css`
  > button {
    margin-right: var(--spacer-xs);
  }

  > button:last-child {
    margin-right: 0;
  }
`;

export const SlideEvent = ({newEvent}: {newEvent: UnifiedEvent}) => {
  const [loading, setLoading] = useState(true);
  const [currentEvent, setCurrentEvent] = useState<UnifiedEvent | null>(null)
  const {widgetProps: {props: {onNext, onPrev, canNext, canPrev} = {}}} = useWidgetProps();
  const slideEvent = (slideCallback: () => void) => {
    setLoading(true);
    slideCallback();
  }

  const isVisibleSlideButton = useMemo(() => (
    onPrev && onNext && (canNext || canPrev)
  ), [onPrev, onNext, canNext, canPrev]);

  useEffect(() => {
    if (newEvent.id === currentEvent?.id && newEvent.status === currentEvent?.status) {
      return
    }

    setTimeout(() => {
      setCurrentEvent(newEvent);
      setLoading(false);
    }, 300);
  }, [newEvent, canNext, canPrev]);

  return (
    <>
      {isVisibleSlideButton && (
        <div css={$prevNext}>
          <Button
            disabled={loading || !canPrev || !onPrev}
            icon={'mdi mdi-24px mdi-chevron-left'}
            className={'p-button-sm p-button-rounded p-button-outlined p-button-secondary'}
            onClick={() => onPrev && slideEvent(onPrev)}
          />
          <Button
            disabled={loading || !canNext || !onNext}
            icon={'mdi mdi-24px mdi-chevron-right'}
            className={'p-button-sm p-button-rounded p-button-outlined p-button-secondary'}
            onClick={() => onNext && slideEvent(onNext)}
          />
        </div>
      )}
    </>
  );
};

import {useCallback, useEffect, useState} from 'react';
import {createCamerasConnection, IEntity, listEntities} from '@netvision/lib-api-gateway';
import {useNotificationSocket} from './useNotificationSocket';
import {DialogConfirmationProps} from '../IWidgetProps';
import {useWidgetProps} from './useWidgetProps';

const aMap = new Map();

export const useEntityMap = <E extends IEntity>(type: E['type'], getKey: (en: E) => string, refine?: (en: E) => E) => {
  const {widgetProps} = useWidgetProps()
  const [entities, setEntities] = useState<Map<string, E>>(aMap);
  const [confirmation, setConfirmation] = useState<DialogConfirmationProps | null>(null);
  
  const fetchEntities = (aborted: boolean) => {
    listEntities<E>(createCamerasConnection(), {type, limit: 1000})
      .then(({ results }) => {
        if (!aborted) {
          let entities = results;
          if (typeof refine === 'function') {
            entities = results.map(refine);
          }
          setEntities(new Map(entities.map((en) => [getKey(en), en])));
        }
      })
      .catch((e) => {
        console.error(e);
        setConfirmation({
          header: 'Серверная ошибка',
          message: 'Произошла ошибка при получении данных. Перезагрузите страницу или обратитесь в техподдержку',
          cancelLabel: 'Закрыть окно',
          confirmLabel: 'Перезагрузить',
          onClose: () => {
            setConfirmation(null);
            widgetProps.props?.onHide();
          },
          onConfirm: () => {
            window.location.reload();
          }
        })
      });
  }

  useEffect(() => {
    let aborted = false;
    fetchEntities(aborted)

    return () => {
      aborted = true;
    };
  }, [type, getKey, refine]);

  useNotificationSocket<E>(
    type,
    useCallback(
      (newEntity) => {
        setEntities((current) => {
          current.set(getKey(newEntity), newEntity);
          return new Map(current);
        });
      },
      [getKey]
    )
  );
  return { entities, confirmation };
};

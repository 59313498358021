/** @jsx jsx */
import { jsx } from '@emotion/react';
import { useWidgetProps } from '../../hooks/useWidgetProps';
import { Widget } from '@netvision/lib-widget-renderer';
import { FC, useEffect, useRef, useState } from 'react';
import { OverlayParams } from '../../components/canvasWidget';
import { attachZoomHandler } from '@netvision/front-utils/lib/common/handleZoom';
import { useLocale } from '../../hooks/useLocale';
import { IWidgetProps } from '../../IWidgetProps';
import { EventsTypes, isAssignmentEvent, ITboEvent, UnifiedEvent } from '../../models';
import { Spinner, ToArchiveButton } from './components/common';
import { fetchCameraSnapshot } from './helpers';
import { CanvasStateService } from '../../services';
import { style } from './style';
import { useEventTypes } from '../../providers/EventTypesProvider';
import { useApiRepository } from '../../hooks/api/useApiRepository';

enum PosterTypes {
  video = 'video',
  image = 'image'
}

const isHtmlVideoElement = (el: HTMLElement): el is HTMLVideoElement => 'play' in el;

export const ExternalFileAdapter: FC<{
  event: UnifiedEvent | ITboEvent;
  height: number | string;
  onOpenArchive: () => void;
}> = ({ event, height, onOpenArchive }) => {
  const locale = useLocale();
  const { api } = useApiRepository();
  const { widgetProps: { mountChildren, areas, props: { canvasStyles, eventName = EventsTypes.AssignmentEvent } = {} } } = useWidgetProps();
  const { eventTypes } = useEventTypes();
  const eventTypeName = eventTypes.get(
    isAssignmentEvent(event)
      ? event.eventType
      : 'tboEventType' in event ? event.tboEventType : ''
  )?.name;
  const zoomRef = useRef<HTMLDivElement | null>(null);
  const overlayRef = useRef<HTMLDivElement | null>(null);

  const videoRef = useRef<HTMLVideoElement | null>(null);
  const imageRef = useRef<HTMLImageElement | null>(null);

  const [loading, setLoading] = useState(true);
  const [notFound, setNotFound] = useState(false);
  const [error, setError] = useState(false);
  const [posterLoadedData, setPosterLoadedData] = useState(false);
  const [poster, setPoster] = useState<{
    src: string;
    type: PosterTypes;
  }>(null!);



  useEffect(() => {
    if (!posterLoadedData || !poster) return;
    const posterElement = poster.type === PosterTypes.image ? imageRef.current : videoRef.current;
    const overlayContainer = overlayRef.current;

    const { canvas } = getAreasChildren(areas);
    if (!posterElement || !overlayContainer || !canvas) return;

    const height = isHtmlVideoElement(posterElement) ? posterElement.videoHeight : posterElement.naturalHeight;
    const width = isHtmlVideoElement(posterElement) ? posterElement.videoWidth : posterElement.naturalWidth;
    const overlay: OverlayParams = {
      overlayContainer,
      overlayBase: posterElement,
      naturalHeight: height,
      naturalWidth: width
    };

    return mountChildren(overlayContainer, [
      {
        ...canvas,
        props: {
          ...canvas.props,
          overlay,
          initState: CanvasStateService.getInitialState({
            eventData: event,
            eventTypeData: eventTypes.get(eventTypeName || '') || {},
            naturalSize: {
              x: overlay.naturalWidth,
              y: overlay.naturalHeight
            },
            styles: canvasStyles?.area || {}
          })
        }
      }
    ]);
    // eslint-disable-next-line
  }, [posterLoadedData]);

  useEffect(() => {
    if (!event) return;
    let url: string | null = null;
    const abortController = new AbortController();

    const getFile = async () => {
      setPoster({
        src: '',
        type: PosterTypes.image
      });
      
      try {
        // Пробуем получить превью от события аналитики
        if (!('getPreview' in api)) {
          throw new Error('getPreview method isn\'t implemented');
        }

        const eventPreview = await api.getPreview({
          signal: abortController.signal,
          eventName: event.type,
          id: event.id,
          timestamp: event.timestamp || new Date(String(event.createdAt)).getTime()
        });

        if (eventPreview instanceof Blob) {
          url = URL.createObjectURL(eventPreview);
          setPoster({ src: url, type: PosterTypes.image });
          return;
        }
      } catch (e) {
        console.error(e);
      }

      try {
        // Если от аналитики не получилось, получаю превью от камеры
        const { results: [stream] } = await api.getEntitiesList<{
          snapshotStreamUrl?: string;
        }>({
          limiter: {
            type: 'Stream'
          },
          filter: {
            attrs: 'snapshotStreamUrl',
            q: [{ key: 'cameraId', operator: '==', value: event.entityId || event.cameraId }]
          }
        });
        if (!stream) {
          throw new Error(`There is no stream for camera with id ${event.entityId || event.cameraId}`)
        }
        const eventTimestamp = Math.round((
          event.timestamp || new Date(String(event.createdAt)).getTime()
        ) / 1000)

        if (abortController.signal.aborted) return

        const res = await fetchCameraSnapshot(
          `${stream.snapshotStreamUrl}&start=${eventTimestamp}`,
          abortController.signal
        );

        if (abortController.signal.aborted) return;
        if (!res) return setNotFound(true);

        if (res instanceof Blob) {
          url = URL.createObjectURL(res);
          setPoster({
            src: url,
            type: PosterTypes.video
          });
          return;
        }
      } catch (e) {
        console.error(e);
        if (!abortController.signal.aborted) {
          setError(true);
        }
      }
    };

    setError(false)
    setLoading(true)
    setNotFound(false)
    getFile().finally(() => setLoading(false))

    return () => {
      abortController.abort('Unmounted');
      url && URL.revokeObjectURL(url);
    };
  }, [event, eventName]);


  useEffect(() => {
    const zoom = zoomRef.current;
    const img = imageRef.current;
    const video = videoRef.current;
    const overlay = overlayRef.current;
    const targetElement = img || video

    if (zoom && targetElement && overlay) {
      const disposer = attachZoomHandler(zoom, [targetElement, overlay]);
      return () => disposer();
    }
    return () => undefined;
  }, [posterLoadedData]);

  return (
    <div ref={zoomRef} style={{ height, width: '100%' }} css={style}>
      {poster?.type === PosterTypes.image ? (
        <img ref={imageRef} src={poster?.src} alt={event.id} onLoad={() => setPosterLoadedData(true)} />
      ) : (
        <video
          width="100%"
          height="100%"
          ref={videoRef}
          src={poster?.src}
          onLoadedData={() => setPosterLoadedData(true)}
        />
      )}
      <div ref={overlayRef} data-name={'overlay'} />
      {loading && <Spinner />}
      {notFound && (
        <div data-name={'info'}>
          {(event.timestamp || new Date(String(event.createdAt)).getTime()) +
            (isAssignmentEvent(event) ? event.assignment?.parameters.stateHistoryTTL || 0 : 0) * 3600 * 1000 >
            Date.now()
            ? locale.externalFilePreviewLocale.previewIsNotReady
            : locale.externalFilePreviewLocale.previewIsNotFound}
        </div>
      )}
      {error && <div data-name={'info'}>{locale.externalFilePreviewLocale.previewLoadingError}</div>}
      <ToArchiveButton onClick={onOpenArchive} />
    </div>
  );
};

function getAreasChildren(areas: IWidgetProps['areas']): {
  canvas: Widget | null;
} {
  let canvas = null;
  if (Array.isArray(areas)) {
    areas.forEach((area) => {
      if (area.name === 'canvas' && area.children && area.children.length > 0) {
        canvas = area.children[0];
      }
    });
  }
  return { canvas };
}

/** @jsx jsx */
import {css, jsx} from '@emotion/react';
import {FC, useCallback, useState, useEffect, useMemo, Dispatch, SetStateAction} from 'react';
import {useLocale} from '../../../hooks/useLocale';
import {patchEventStatus} from '../../../requests/patchEventStatus';
import {useEventExpirationTime} from '../../../hooks/useEventExpirationTime';
import {getExpiration, getIsMissed, getNeedsReaction} from '../../../eventUtils';
import {Success} from '../../ui/icons/Success';
import {Attention} from '../../ui/icons/Attention';
import {Drill} from '../../ui/icons/Drill';
import {CountDown} from '../../ui/CountDown';
import {TimeDecay} from '../../ui/icons/TimeDecay';
import {Button} from 'primereact/button';
import {IEntity, getNgsiConnection} from '@netvision/lib-api';
import {useWidgetProps} from '../../../hooks/useWidgetProps';
import {history} from '@netvision/lib-history';
import {useToastRef} from '../../../providers/ToastProvider';
import {useNeedUpdateContext} from '../../../providers/NeedUpdateProvider';
import {buttonsCss, containerCss, countDownCss} from './styled-actions';
import {CommandButton} from '../CommandButton/CommandButton';
import {
  EventsTypes,
  AssignmentEventStatus,
  isAssignmentEvent,
  TboPermission,
  AssignmentPermission,
  UnifiedEvent
} from '../../../models';
import {useEventDataInfo, useGetPermissionsById} from '../../../hooks';
import {SlideEvent} from './components/slide-event';

const secondaryButton = css`
  position: relative;
  overflow: initial;

  &:not(:hover) {
    color: var(--text-color-secondary) !important;
  }

  & > [data-tooltip] {
    background: var(--surface-a);
    width: max-content;
    border-radius: var(--border-radius);
    padding: calc(10rem / var(--bfs)) calc(15rem / var(--bfs));
    opacity: 0;
    pointer-events: none;
    transition: opacity var(--transition-duration) ease;
    position: absolute;
    top: calc(100% + 10rem / var(--bfs));
    left: 50%;
    transform: translateX(-50%);
  }

  &:hover > [data-tooltip] {
    opacity: 1;
  }
`

const getCommands =
  (id: string, onSuccess: (status: string) => void, onError: () => void, eventType: EventsTypes) => (status: string) =>
    patchEventStatus(id, status, eventType)
      .then(() => onSuccess(status))
      .catch(onError);

export const Actions: FC<{
  event: UnifiedEvent;
  className?: string;
  resolutionRequired: boolean;
  onCleanQueue?: () => void;
  setActualEvent: Dispatch<SetStateAction<UnifiedEvent>>;
}> = ({
  event: evt,
  className,
  resolutionRequired,
  onCleanQueue,
  setActualEvent
}) => {
  const event = useMemo(() => evt, [evt]);
  const {permissions: cameraPermissions} = useGetPermissionsById(
    isAssignmentEvent(event) ? event.assignment?.entityId || event.entityId || '' : event.cameraId || ''
  );

  const hasPermissionChangeEventStatus = cameraPermissions?.some((permissionName) => {
    return (
      permissionName === TboPermission.ChangeEventStatus || permissionName === AssignmentPermission.ChangeEventStatus
    );
  });

  const canChangeEventStatus = resolutionRequired && hasPermissionChangeEventStatus
  const locale = useLocale();
  const toastRef = useToastRef();

  const [currentStatus, setCurrentStatus] = useState(event.status);
  const [tasks, setTasks] = useState([] as IEntity[]);

  const { widgetProps: { props: { tasksLink, isEventWithTimer } = {} } } = useWidgetProps()
  const {event: eventName} = useEventDataInfo();
  const {toggleNeedUpdate} = useNeedUpdateContext();

  const time = useEventExpirationTime(event);
  const needsReaction = getNeedsReaction(event, time);
  const expiration = getExpiration(event);
  const isMissed = getIsMissed(event, time);

  const onCommand = useMemo(() => {
    return getCommands(
      event.id,
      (status) => {
        toastRef.current?.show({
          severity: 'success',
          summary: locale.actions.success,
          detail: locale.actions.statusIsUpdating
        });
        setCurrentStatus(status);
        setActualEvent({...event, status: status as AssignmentEventStatus});
        toggleNeedUpdate();
      },
      () => {
        toastRef.current?.show({
          severity: 'error',
          summary: locale.actions.error,
          detail: locale.actions.couldNotUpdateStatus
        });
      },
      eventName
    );
  }, [toastRef, locale, event, toggleNeedUpdate, eventName]);

  const goToTask = useCallback(
    ({id}: IEntity) => {
      history.push({pathname: tasksLink || '/tasks', search: `task="${id}"`});
    },
    [tasksLink]
  );

  const buttons = [
    {
      status: AssignmentEventStatus.Handled,
      label: locale.reactionDialog.handled,
      color: 'var(--warning-color)',
      icon: (disabled: boolean) => <Attention disabled={disabled} />
    },
    {
      status: AssignmentEventStatus.FalsePositive,
      label: locale.reactionDialog.falsePositive,
      color: 'var(--success-color)',
      icon: (disabled: boolean) => <Success disabled={disabled} />
    },
    {
      status: AssignmentEventStatus.Alert,
      label: locale.reactionDialog.alert,
      color: 'var(--error-color)',
      icon: (disabled: boolean) => <Drill disabled={disabled} />
    }
  ];

  const getTasks = async () => {
    const connetion = await getNgsiConnection();
    const {results} = await connetion.v2.listEntities({
      type: 'UserTask',
      keyValues: true,
      q: `relatedEntities.id==${event.id}`
    });
    results && setTasks(results);
  };

  useEffect(() => {
    setCurrentStatus(event.status);
  }, [event]);

  useEffect(() => {
    currentStatus === AssignmentEventStatus.Alert && getTasks();
  }, [currentStatus]);
  
  return !expiration ? null : (
    <div className={className} css={containerCss}>
      {needsReaction && isEventWithTimer && (
        <div css={countDownCss}>
          <TimeDecay />
          <CountDown endTime={expiration} />
        </div>
      )}
      <SlideEvent newEvent={event} />
      {!isMissed && canChangeEventStatus && (
        <div css={buttonsCss}>
          {currentStatus === AssignmentEventStatus.Alert
            ? tasks.map((task: IEntity) => (
                <Button
                  key={task.id}
                  icon={'mdi mdi-24px mdi-text-box-check'}
                  className={'p-button-sm p-button-rounded p-button-outlined p-button-secondary p-button-success'}
                  title={`${task.title || ''}`}
                  onClick={() => goToTask(task)}
                />
              ))
            : buttons.map((_props) => {
                return jsx(CommandButton, {..._props, key: _props.status, currentStatus, onClick: onCommand});
              })}
          {onCleanQueue &&
            <Button
              icon='mdi mdi-24px mdi-close-box-multiple-outline'
              className="p-button-secondary p-button-outlined"
              css={secondaryButton}
              onClick={onCleanQueue}
            >
              <div data-tooltip>{locale.actions.cleanQueue}</div>
            </Button>
          }
        </div>
      )}
    </div>
  );
};

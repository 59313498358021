import {AssignmentEventStatus, IAssignmentEvent, EventStructure, isAssignmentEvent, UnifiedEvent} from './models/';

export function getIsReacted(event: UnifiedEvent) {
  return event.status !== AssignmentEventStatus.NotHandled;
}

export function getExpiration(event: UnifiedEvent) {
  if (!isAssignmentEvent(event)) return Number(new Date());

  return (
    event.handlingTimeout && (event.timestamp || new Date(String(event.createdAt)).getTime()) + event.handlingTimeout * 1000
  ) || new Date().getTime();
}

export function getTimeLeft(event: UnifiedEvent, time: number) {
  const exp = getExpiration(event);
  return (exp && Math.max(0, exp - time)) || undefined;
}

export function getNeedsReaction(event: UnifiedEvent, time: number) {
  const exp = getExpiration(event);
  return !!exp && time < exp && !getIsReacted(event);
}

export function getIsMissed(event: UnifiedEvent, time: number) {
  if ('handlingTimeout' in event) {
    const exp = getExpiration(event);
    return !!exp && time >= exp && !getIsReacted(event);
  }

  return false;
}

export function cutActiveEvents(time: number, events: IAssignmentEvent[]): [IAssignmentEvent[], IAssignmentEvent[]] {
  const active: IAssignmentEvent[] = [];
  const rest: IAssignmentEvent[] = [];
  events.forEach((ev) => {
    if (getNeedsReaction(ev, time)) {
      active.push(ev);
    } else {
      rest.push(ev);
    }
  }, []);
  return [active.sort((a, b) => (getExpiration(a) || 0) - (getExpiration(b) || 0)), rest];
}

export function eventUnifier (event: EventStructure) {
  if (!('assignmentTypeTitle' in event)) {
    return { ...event }
  }

  const parsedAssignment = event.assignment ? JSON.parse(event.assignment) : {}

  return {
    ...event,
    type: 'AssignmentEvent',
    cameraId: event.entityId,
    location: event.location,
    eventType: event.assignmentEventTypeName,
    entityType: parsedAssignment.entityType,
    assignmentParameters: JSON.parse(event.assignmentParameters),
    assignment: 'assignment' in event
      ? {
          ...parsedAssignment,
          assignmentTypeTitle: event.assignmentTypeTitle,
          assignmentIconClass: event.assignmentTypeIconClass
        }
      : null
  }
}

import React, {createContext, FC, useContext} from 'react';
import {useEntityMap} from '../hooks/useEntityMap';
import {IEntity} from '@netvision/lib-api-gateway';
import {useEventDataInfo} from '../hooks';
import {DialogConfirmationProps} from '../IWidgetProps';

type IEntityListProvider<E extends IEntity> = {
  Provider: FC;
  useProvider: () => { eventTypes: Map<string, E>; confirmation: DialogConfirmationProps | null; };
};

export const createEntityMapProvider = <E extends IEntity>(
  getKey: (en: E) => string,
  refine?: (en: E) => E
): IEntityListProvider<E> => {

  const ctx = createContext<{
    eventTypes: Map<string, E>;
    confirmation: DialogConfirmationProps | null;
  }>(null!);
  const Provider: IEntityListProvider<E>['Provider'] = ({children}) => {
    const {eventType} = useEventDataInfo()
    const {entities: eventTypes, confirmation} = useEntityMap<E>(eventType, getKey, refine);
    return <ctx.Provider value={{eventTypes, confirmation}}>{children}</ctx.Provider>;
  };

  const useProvider = () => useContext(ctx);

  return {
    Provider,
    useProvider
  };
};

import {IEntity} from '@netvision/lib-api';
import {getFieldByType} from './getFieldByType';
import {MetaDataType} from '../Root';
import {ModalField} from '../models';

type FieldViewStructure = {
  field: string;
  header: string;
  body: (rowData: IEntity) => JSX.Element | null | undefined
};

export const createFields = (
  {
    tableSetting: settings,
    locale: locales,
    eventType: entityType
  }: MetaDataType,
  anaParamsLocale: Pick<ModalField, 'buttonText' | 'title' | 'textToOpen' | 'header'>,
  overrideFields?: Record<string, { disable: boolean }>
): FieldViewStructure[] => {
  if (!settings || !locales || !entityType) return []
  const {columns = {}} = settings;
  const fields = Object.entries(columns).map(([columnName, columnSettings]) => {
    const {cell} = columnSettings;
    const col: FieldViewStructure = {
      field: columnName,
      header: locales?.[columnName] ?? columnName,
      body: (rowData: IEntity) => getFieldByType({
        rowData,
        cell,
        field: columnName,
        locales,
        entityType
      })
    };
    return col;
  });

  const isLegacyMeta = fields.some(({ field }) => {
    return ['assignment.providerParameters', 'assignmentParameters'].includes(field)
  });

  !isLegacyMeta && fields.push({
    field: 'assignmentParameters',
    header: anaParamsLocale.header || '',
    body: (rowData: IEntity) => {
      return getFieldByType({
        rowData,
        cell: {
          ...anaParamsLocale,
          type: 'modal',
          cell: { type: 'object' }
        },
        field: 'assignmentParameters',
        locales,
        entityType
      })
    }
  });
  
  return fields.filter(c => !(overrideFields && overrideFields[c.field]?.disable))
};

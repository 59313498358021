import {useGetMetaData, useLocale} from '.';
import {createFields} from '../helpers';
import {useLang} from '../providers/LangProvider';
import {useWidgetProps} from '../hooks/useWidgetProps';
import {useEventDataInfo} from './event';
import { MetaDataType } from '../Root';

export const useGetFields = () => {
  const lang = useLang();
  const {anaParamsLocale} = useLocale();
  const {event} = useEventDataInfo();
  const {widgetProps: { props }} = useWidgetProps();
  const metaData = useGetMetaData(event, lang) || {} as MetaDataType;
  const fields = createFields(metaData, anaParamsLocale, props?.fields);
  return fields;
};
